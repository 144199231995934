import React from 'react';

import AuthContextWrapper from './context/AuthContext';
import LoadingIndicator from './components/LoadingIndicator';
import Routes from './routes';

import './global.css';

const App: React.FC = () => {
  return (
    <AuthContextWrapper loadingIndicator={<LoadingIndicator />}>
        <Routes />
    </AuthContextWrapper>
  );
};

export default App;
