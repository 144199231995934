import React from 'react';
import { FiLoader } from 'react-icons/fi';

import './styles.css';

interface LoadingProps {
  message?: string;
}

const Loading: React.FC<LoadingProps> = ({ message }) => {
  return (
    <div className="loading-indicator-container">
      <FiLoader />
      <span>{message ?? 'Carregando'}</span>
    </div>
  );
};

export default Loading;
