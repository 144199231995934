import React, { useContext, useState, useEffect } from 'react';

import api from '../services/api';

export interface User {
  email: string;
  accessToken: string;
}

const AuthContext = React.createContext<{
  user?: User;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}>({ user: undefined, setUser: () => {} });

export const useAuth = () => useContext(AuthContext);

const AuthContextWrapper: React.FC<{ loadingIndicator: React.ReactNode }> = ({
  children,
  loadingIndicator,
}) => {
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);

  async function refreshToken() {
    try {
      const response = await api.post('refresh_token');
      const { email, accessToken } = response.data;

      setUser({ email, accessToken });
    } catch (error) {
      if (error.response?.status === 403) {
        setUser(undefined);
        setIsLoading(false);

        await api.post('logout');

        alert('Outro login detectado. Sua sessão foi encerrada.');

        window.location.replace('/login');
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    refreshToken();

    const id = api.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response?.status !== 401) {
          return new Promise((resolve, reject) => reject(error));
        }

        const excluded_routes = [
          'register',
          'authenticate',
          'refresh_token',
          'forgot_password',
          'reset_password',
        ];

        if (excluded_routes.includes(error.config.url)) {
          return new Promise((resolve, reject) => reject(error));
        }

        return refreshToken();
      }
    );

    return () => {
      api.interceptors.response.eject(id);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {isLoading ? loadingIndicator : children}
    </AuthContext.Provider>
  );
};

export default AuthContextWrapper;
