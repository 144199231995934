import React from 'react';

import Loading from '../Loading';

import './styles.css';

const LoadingIndicator: React.FC = () => {
  return (
    <div id="loading-indicator">
      <Loading />
    </div>
  );
};

export default LoadingIndicator;
