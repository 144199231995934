import axios from 'axios';

const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://jurisfai-next.herokuapp.com'
    : 'http://localhost:3333';

const api = axios.create({
  baseURL,
  responseType: 'json',
  withCredentials: true,
});

export default api;
