import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';

import LoadingIndicator from './components/LoadingIndicator';

const Login = React.lazy(() => import('./pages/Login'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const DocumentsList = React.lazy(() => import('./pages/DocumentsList'));
const ViewDocument = React.lazy(() => import('./pages/ViewDocument'));
const UploadDocuments = React.lazy(() => import('./pages/UploadDocuments'));
const Jurisprudence = React.lazy(() => import('./pages/Jurisprudence'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));

const Routes: React.FC = () => {
  const legacyRouteRedirects = (
    <Route
      path="/document"
      render={props => (
        <Redirect
          to={props.history.location.pathname.replace('document', 'documents')}
        />
      )}
    />
  );

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/documents" />} />

          {legacyRouteRedirects}

          <PrivateRoute path="/documents/upload" component={UploadDocuments} />
          <PrivateRoute path="/documents/:id" component={ViewDocument} />
          <PrivateRoute path="/documents" component={DocumentsList} />
          <PrivateRoute path="/juris" component={Jurisprudence} />

          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgot_password" component={ForgotPassword} />
          <Route path="/reset/:id/:token" component={ResetPassword} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
